import type { GtEvent, Member } from '../types';
import AjaxService, { AjaxError } from 'services/ajax';
import auth from 'utils/auth';

const ajax = AjaxService();

export type EventAndOwner = {
  isOwner: boolean | undefined;
  memberId: string | undefined;
} & GtEvent;

type EventsAndPrimaryEventIdResponse = {
  gtEvent: EventAndOwner | null;
  members: Member[] | null;
  primaryEventId: string | null;
};

const eventsApi = {
  /**
   * Fetch the current logged in user's events
   */
  fetchEventsAndPrimaryEventId(): Promise<{
    events: EventAndOwner[];
    primaryEventId: string | null;
  }> {
    return ajax
      .post<EventsAndPrimaryEventIdResponse>('/api/get-events-and-primary-event-id', {
        body: {
          accountId: auth.userId(),
        },
      })
      .then((response) => {
        const primaryEventId = response.primaryEventId;
        const events =
          response.members
            ?.map((m: Member) => Object.assign(m.gtEvent!, { isOwner: m.isOwner, memberId: m.id }))
            .filter((ev: EventAndOwner) => !ev.isTrial) ?? [];

        return { events, primaryEventId };
      })
      .catch((error: AjaxError) => {
        if (error.status === 401) {
          return { events: [], primaryEventId: null };
        }

        throw error;
      });
  },
};

export default eventsApi;
