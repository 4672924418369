import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import type { GtEvent } from 'types';
import eventsApi from 'services/eventsApi';

type EventList = {
  all: GtEvent[];
  primary: GtEvent | null;
};

const CustomerEventListContext = createContext<EventList>({
  all: [],
  primary: null,
});

export const useCustomerEventList = () => useContext(CustomerEventListContext);

export const CustomerEventListProvider = ({ children }: PropsWithChildren) => {
  const [eventList, setEventList] = useState<EventList>({
    all: [],
    primary: null,
  });

  useEffect(() => {
    eventsApi.fetchEventsAndPrimaryEventId().then(
      ({ events, primaryEventId }) => {
        const primary = events.find((event) => event.id === primaryEventId) ?? null;

        setEventList({
          all: events,
          primary,
        });
      },
      (err) => {
        let message = 'Unexpected error occurred';

        if (typeof err === 'string') {
          message = err;
        } else if (err instanceof Error) {
          message = err.message;
        }

        if (message.toLowerCase() === 'unauthorized') {
          return;
        }

        console.error(`Couldn't fetch customer's event list: ${message}`);
      }
    );
  }, []);

  return <CustomerEventListContext.Provider value={eventList}>{children}</CustomerEventListContext.Provider>;
};
